import React from "react"
import styled from "styled-components"
import { FaBookReader, FaEnvelope, FaPhone } from 'react-icons/fa';

import { Container, StyledLink } from "../global"

const Footer = () => (
  <FooterWrapper id="footer">
    <BrandContainer>
      <Logo>
        <StyledLink
          to="/"
        >
          <FaBookReader /> SALOM
        </StyledLink>
      </Logo>
      <InfoContainer>
        <InfoText>
          <FaEnvelope /> info@salom.si
        </InfoText>
        <InfoText>
          <FaPhone /> 041 296 509
        </InfoText>
      </InfoContainer>
    </BrandContainer>
    <CopyrightContainer>
      © 2020 Salom, <StyledLink to="/splosni-pogoji-poslovanja">splosni pogoji poslovanja</StyledLink>. Izdelava spletne strani: <a style={{ "text-decoration": 'none', "color": "#000" }} href="https://www.nkoporec.com">nkoporec.com</a>
    </CopyrightContainer>
  </FooterWrapper>
)

const FooterWrapper = styled.footer`
  background-color: white;
  margin: 80px 0 0;
  padding: 0 0 80px;
`

const Logo = styled.div`
  font-family: ${props => props.theme.font.extrabold};
  ${props => props.theme.font_size.regular};
  color: ${props => props.theme.color.black.regular};
  text-decoration: none;
  letter-spacing: 1px;
  margin: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 9;
  text-decoration: none;
  outline: 0px;
`

const BrandContainer = styled(Container)`
  position: relative;
  padding-top: 48px;
  display: flex;
  align-items: flex-end;

  @media (max-width: ${props => props.theme.screen.sm}) {
  }
`

const InfoContainer = styled.div`
  right: 0;
  position: absolute;
  display: inline-flex;
  font-family: ${props => props.theme.font.extrabold};
  ${props => props.theme.font_size.regular};
  color: ${props => props.theme.color.black.regular};
  text-decoration: none;
  letter-spacing: 1px;
  margin: 0;
  justify-content: center;
  align-items: center;
  z-index: 9;
  top: 0.5rem;
  outline: 0px;
  @media (max-width: ${props => props.theme.screen.md}) {
    display: block;
    margin-right: 2rem;
  }
`

const InfoText = styled.h5`
  padding: 1rem;
  @media (max-width: ${props => props.theme.screen.md}) {
    padding: 0;
  }
`

const CopyrightContainer = styled.div`
  margin-top: 5rem;
  opacity: 0.3;
  text-align: center;
`

export default Footer
